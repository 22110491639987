<template>
  <div>
    <b-row class="mt-4" no-gutters>
      <b-col cols="12" md="12" class="p-4">
        <div class="pr-3">
          <div class="p-4 bg-dark-jungle rounded">
            <h3 class="text-uppercase">{{ "Business" | __t("projects__settings__headline__business") }}</h3>

            <p class="mb-0 ">
              <small>{{ "Logo" | __t("projects__settings__headline__logo") }}</small>
            </p>
            <settings-logo v-if="!isLoading" :project-id="projectId" :logo.sync="logo" />

            <p class="mt-4 mb-0">
              <small>{{ "Name" | __t("projects__settings__headline__name") }}</small>
            </p>
            <base-input
              v-model="name"
              :placeholder="'Insert the name of your space...' | __t('placeholder__insert-the-name-of-your-space')"
            />

            <p class="mt-4 mb-0">
              <small>{{ "Description" | __t("projects__settings__headline__description") }}</small>
            </p>
            <base-textarea
              v-model="description"
              :placeholder="'Insert the description of your space...' | __t('placeholder__insert-the-description-of-your-space')"
              :max-number-of-words="200"
            />

            <!-- <div class="mt-4 pr-3">
          <p class="mb-0 text-uppercase font-weight-bold">Business Website</p>

          <div class="d-flex p-1 bg-dark-jungle rounded">
            <base-input v-model="websitePrefix" disabled style="width: 90px" />
            <base-input v-model="websiteUrl" placeholder="Insert your website..." class="website-url" />

            <b-button @click="triggerScrapeWebsite" variant="success">Check Website</b-button>
          </div>
        </div> -->
          </div>
        </div>
      </b-col>
    </b-row>

    <spacer />
    <b-row no-gutters>
      <b-col cols="12" md="12" class="pl-4">
        <div class="p-3 bg-dark-jungle rounded">
          <p class="mb-0 text-uppercase font-weight-bold">
            {{ "Remove Business" | __t("projects__settings__headline__remove-business") }}
          </p>
          <p>
            {{
              "Remove the current space and all the data related."
                | __t("projects__settings__remove-the-current-space-and-all-the-data")
            }}
          </p>

          <b-button @click="isShowingModal = true" variant="outline-danger">{{ "Remove" | __t("button__remove") }}</b-button>
        </div>
      </b-col>
    </b-row>

    <b-modal
      title="Confirm Deletion"
      v-model="isShowingModal"
      @hidden="isShowingModal = false"
      body-bg-variant="dark"
      centered
      hide-header
      hide-footer
    >
      <p>
        {{
          "Confirm the deletion of your space and the data related."
            | __t("projects__settings__confirm-the-deletion-of-your-space")
        }}
      </p>

      <p>
        <em>{{ "This action cannot be undone" | __t("message__action-cannot-undone") }}.</em>
      </p>

      <div class="text-right">
        <b-button variant="empty" @click="isShowingModal = false" class="rounded text-white text-uppercase">
          {{ "Cancel" | __t("button__cancel") }}
        </b-button>

        <b-button variant="danger" @click="triggerRemoveSettings" :disabled="isSending" class="rounded text-uppercase">
          {{ "Delete" | __t("button__remove") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { GET, UPDATE, REMOVE } from "@/store/actions";

import { BModal, BRow, BCol, BButton } from "bootstrap-vue";

import Spacer from "@/components/Layouts/Spacer.vue";
import BaseInput from "@/components/Forms/Base/BaseInput.vue";
import BaseTextarea from "@/components/Forms/Base/BaseTextarea.vue";

import SettingsLogo from "./Logo.vue";

export default {
  name: "Settings",
  components: { BModal, BRow, BCol, BButton, Spacer, BaseInput, BaseTextarea, SettingsLogo },
  computed: {
    ...mapState({
      settings: (state) => state.projectsSettings.items,
    }),
    projectId() {
      return this.$route.params.projectId;
    },
  },
  data: () => ({
    name: null,
    description: null,
    logo: null,
    websiteUrl: null,

    websitePrefix: "https://",

    isSending: false,
    isLoading: true,
    isShowingModal: false,
  }),
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions("projectsSettings", { getSettings: GET, updateSettings: UPDATE, removeProject: REMOVE }),
    async init() {
      this.isLoading = true;
      // TODO: We actually already have fetch those data before inside @/modules/projects/views/Project/index.vue
      // Avoid the double fetching by using the data from the state
      const { projectId } = this;
      const data = await this.getSettings({ projectId });

      for (const key in data) {
        this[key] = data[key];
      }

      this.$watch("name", () => { this.triggerUpdateSettings(); }); /* prettier-ignore */
      this.$watch("description", () => { this.triggerUpdateSettings(); }); /* prettier-ignore */
      this.$watch("logo", () => { this.triggerUpdateSettings(); }); /* prettier-ignore */

      this.isLoading = false;
    },

    async triggerUpdateSettings() {
      this.isSending = true;

      const { projectId, logo, name, description } = this;
      await this.updateSettings({ projectId, logo, name, description });

      this.isSending = false;
    },
    async triggerRemoveSettings() {
      this.isSending = true;

      const { projectId } = this;
      await this.removeProject({ projectId });

      this.$router.push({ name: "Projects" });

      this.isSending = false;
    },

    /* async triggerScrapeWebsite() {
      const { websiteUrl: url } = this;
      await scrapeWebsite({ url: `https://${url}` });
    }, */
  },
};
</script>

<style lang="scss">
.website-url {
  input {
    padding-left: 0px !important;
  }
}
</style>
