<template>
  <div class="base-textarea w-100">
    <b-form-textarea
      :value.sync="localValue"
      :name="name"
      :placeholder="placeholder"
      :disabled="disabled"
      :id="id"
      :rows="rows"
      :max-rows="maxRows"
    />

    <b-row v-if="maxNumberOfWords">
      <b-col></b-col>

      <b-col class="text-right">
        <small class="text-white">{{ numberOfWords }} / {{ maxNumberOfWords }} {{ "words" | __t("common__words") }}</small>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BFormTextarea } from "bootstrap-vue";

import { getWordsCount } from "@/helpers/strings";

export default {
  name: "BaseTextarea",
  components: { BRow, BCol, BFormTextarea },
  props: {
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },

    rows: {
      type: Number,
      default: 2,
    },
    maxRows: {
      type: Number,
      default: 10,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    maxNumberOfWords: {
      type: Number,
      default: null,
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    numberOfWords() {
      return getWordsCount(this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_settings";

.base-textarea {
  textarea {
    background-color: transparent !important;
    color: white;
    border: 0px !important;
    font-size: 1.3rem;
  }

  textarea[disabled="disabled"] {
    background-color: $white-100;
    color: $gray-darker;
  }

  textarea:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }
}
</style>
