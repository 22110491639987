<template>
  <div>
    <div v-if="isUploading" class="upload-area rounded-lg p-2" :style="style">
      <base-upload v-if="!file && !isSuccess" @upload="handleUpload" :accepted-types="acceptedTypes" :extensions="extensions" />

      <div v-else-if="file && !isSuccess" class="p-4">
        <fieldset class="mb-3">
          <p class="mb-0">
            <small> {{ file?.name }} - {{ Math.floor(+file?.size / 1000) }}KB </small>
          </p>
        </fieldset>

        <b-progress v-if="progress >= 0" :value="progress" :max="100" show-progress animated class="mt-3">
          <b-progress-bar :value="progress">{{ Number(progress).toFixed(1) }}%</b-progress-bar>
        </b-progress>
      </div>
    </div>

    <div v-else class="text-center">
      <b-img :src="logo" fluid rounded />
      <div>
        <b-button @click="removeLogo" variant="transparent">
          <span class="text-white">{{ "Remove" | __t("button__remove") }}</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { UPLOAD } from "@/store/actions";

import { BProgress, BProgressBar, BImg, BButton } from "bootstrap-vue";

import BaseUpload from "@/components/Forms/Base/BaseUpload";

export default {
  name: "LogoUpload",
  components: { BProgress, BProgressBar, BImg, BButton, BaseUpload },
  props: {
    projectId: {
      type: String,
      required: true,
    },
    logo: {
      type: String,
      default: "",
    },
  },
  computed: {
    localLogo: {
      get() {
        return this.logo;
      },
      set(value) {
        this.$emit("update:logo", value);
      },
    },
    style() {
      const { isSuccess } = this;
      return isSuccess
        ? "border: 1px solid #5900d9; background-color: #151718;"
        : `border: 1px dashed; background-color: #5900d9;`;
    },
  },
  data: () => ({
    file: null,

    fileId: null,
    fileName: null,
    fileUrl: null,

    progress: null,
    extensions: ".jpg, .jpeg, .png, .webp, .svg",
    acceptedTypes: ["image/jpeg", "image/png", "image/webp", "image/svg+xml"],

    isSuccess: false,
    isLoading: false,
    isUploading: false,
  }),
  mounted() {
    const { logo } = this;
    if (!logo) this.isUploading = true;
  },
  methods: {
    ...mapActions("storage", { uploadFile: UPLOAD }),
    async handleUpload(file) {
      this.file = file;
      this.fileName = file?.name;

      const { projectId } = this;
      const { isSuccess, id, name, url } = await this.uploadFile({
        projectId,
        file,
        stateCallback: this.stateChanged,
        errorCallback: this.errorCallback,
        uploadedCallback: this.uploadedCallback,
      });

      this.isSuccess = isSuccess;

      if (isSuccess) {
        this.fileId = id;
        this.fileName = name;
        this.fileUrl = url;

        this.localLogo = url;
        this.isUploading = false;
      }
    },
    handleProgress(snapshot) {
      this.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    },
    handleLoading(value) {
      this.isLoading = value;
    },

    stateChanged(snapshot) {
      this.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    },
    errorCallback(message) {
      this.isLoading = false;
      this.handleLoading(this.isLoading);
      this.errorMessage = message;
    },
    uploadedCallback() {
      this.isLoading = false;
      this.handleLoading(this.isLoading);
    },

    removeLogo() {
      this.localLogo = null;
      this.isUploading = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_settings";

.theme--default .upload-area {
  border-color: $gray-light-100;

  &.is_active,
  &:hover {
    border-color: $blue-deep;
  }
  &.is_disabled {
    border-color: $gray-light-100 !important;

    p {
      color: $gray-light-100 !important;
    }
  }

  .form-control {
    background-color: #272a34 !important;
    border-color: #9b9b9b;
    color: white;
  }
}
</style>
